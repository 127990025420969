import { Link } from "gatsby";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import React from "react";
// Components
import { CategoryBadge, Heading } from "@components";
// Images
import featuredImage from "@images/placeholder.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogDetails = ({ pageContext }) => {
    const { slug, headline, service, blog_category, content, publishedAt } =
        pageContext;

    const relatedBlogs = blog_category.blogs?.length
        ? blog_category.blogs.filter((blog) => blog.slug !== slug)
        : [];

    return (
        <>
            <div className="w-full">
                <div className="max-w-1280 mx-auto py-8 px-4 lg:px-16 flex flex-col md:flex-row justify-between gap-x-4 gap-y-5">
                    <div className="basis-full md:basis-[70%]">
                        <div className="mb-2">
                            {blog_category && (
                                <CategoryBadge label={blog_category.name} />
                            )}
                        </div>

                        {/* Title */}
                        <p className="text-3xl capitalize font-semibold">
                            {headline}
                        </p>
                        <p className="text-gray-400 text-sm">
                            Published{" "}
                            <span className="font-medium text-gray-500">
                                {moment(publishedAt, "YYYYMMDD").fromNow()}{" "}
                            </span>
                            on{" "}
                            <span className="font-medium text-gray-500">
                                {moment(publishedAt, "YYYYMMDD").format(
                                    "MMM DD, YYYY"
                                )}
                            </span>
                        </p>

                        <hr className="my-4" />

                        {content?.data?.content && (
                            <div className="mt-6 box-border">
                                <Markdown
                                    options={{
                                        overrides: {
                                            p: {
                                                props: {
                                                    className: "",
                                                },
                                            },
                                            img: {
                                                props: {
                                                    style: {
                                                        borderRadius: "16px",
                                                        display: "block",
                                                        border: "1px solid #ccc",
                                                        maxWidth: "100%",
                                                        margin: "24px auto",
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {content?.data?.content}
                                </Markdown>
                            </div>
                        )}
                    </div>
                    <div className="basis-full md:basis-[30%]">
                        <Heading color={"black"} weight={"bold"} size={"lg"}>
                            Related Blogs
                        </Heading>

                        {/* Related Blogs */}
                        <div className="flex flex-col gap-y-2">
                            {relatedBlogs.map((blog) => (
                                <Link
                                    to={`/blog/${blog.slug}`}
                                    key={blog.slug}
                                    className="flex justify-between gap-x-2 mt-3"
                                >
                                    {blog.content.medias?.length ? (
                                        <GatsbyImage
                                            image={getImage(
                                                blog.content.medias[0].localFile
                                            )}
                                            className="w-full sm:w-1/4 h-[80px] object-cover rounded-md"
                                        />
                                    ) : (
                                        <img
                                            className="w-full sm:w-1/4 h-[80px] object-cover rounded-md"
                                            src={
                                                pageContext?.content?.medias[0]
                                                    ?.file?.url || featuredImage
                                            }
                                            alt={blog.slug}
                                        />
                                    )}

                                    <div className="w-full sm:w-3/4">
                                        <Heading
                                            color={"black"}
                                            weight={"bold"}
                                        >
                                            {blog.headline.length > 40
                                                ? `${blog.headline.substring(
                                                      0,
                                                      40
                                                  )}...`
                                                : blog.headline}
                                        </Heading>
                                        <p className="text-gray-400 text-xs">
                                            {moment(
                                                blog.publishedAt,
                                                "YYYYMMDD"
                                            ).format("MMM DD, YYYY")}{" "}
                                            |{" "}
                                            {(blog_category &&
                                                blog_category?.name) ||
                                                "Uncategorized"}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetails;
